export const AwardsData = [
  "USCF Expert",
  "Eagle Scout",
  "TEDxNYU '20 Speaker Finalist",
  "1st @ NYU SuperHacks '18",
  "3rd @ HackNY '18",
  "5th @ PennApps '19",
  "HackNYU '20 Judge",
  "NYU 2020 Youngest Baccalaureate Degree Candidate",
  "NYU 2020 Computer Science & Engineering Leadership Award",
  "Top 100 Chess Player in the U.S. for ages 14, 15, 16, 17, 18",
  "1st Chair Trombone, California All Southern Honor Band",
];
