export const ExperienceData = [
  {
    company: "Google",
    title: "Software Engineer",
  },
  {
    company: "Roblox",
    title: "Software Engineer",
  },
  {
    company: "JPMorgan",
    title: "Software Engineer Intern",
  },
  {
    company: "Ya Ya Creations",
    title: "Software Engineer Intern",
  },
  {
    company: "NYU Computer Science Department",
    title: "Teaching Assistant",
  },
  {
    company: "HackNYU",
    title: "Organizer",
  },
  {
    company: "Cal State LA Student Government",
    title: "Board of Directors",
  },
  {
    company: "Pat Brown Institute for Public Affairs",
    title: "Public Policy Intern",
  },
];
