export const EducationData = [
  {
    school: "New York University",
    description1: "B.S. Computer Science, ",
    description2: "magna cum laude",
  },
  {
    school: "New York University Shanghai",
    description1: "Senior Fall Abroad",
    description2: "",
  },
  {
    school: "Cal State LA",
    description1: "Early Entrance Program",
    description2: "",
  },
];
